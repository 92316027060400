import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';
import { UnifiedThemeProvider } from '@backstage/theme';
import DarkIcon from '@material-ui/icons/Brightness2';
import LightIcon from '@material-ui/icons/WbSunny';
import MainIcon from '@material-ui/icons/Brightness6';
import { mainTheme, mainLightTheme, mainDarkTheme } from './main';

export const themes: (Partial<AppTheme> & Omit<AppTheme, 'theme'>)[] = [
  {
    id: 'linebreak-main-theme',
    title: 'Linebreak Main Theme',
    variant: 'dark',
    icon: <MainIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={mainTheme} children={children} />
    ),
  },
  {
    id: 'linebreak-light-theme',
    title: 'Linebreak Light Theme',
    variant: 'light',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={mainLightTheme} children={children} />
    ),
  },
  {
    id: 'linebreak-dark-theme',
    title: 'Linebreak Dark Theme',
    variant: 'dark',
    icon: <DarkIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={mainDarkTheme} children={children} />
    ),
  },
];
