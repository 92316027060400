import {
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

// shapes options: wave, wave2, round

export const mainTheme = createUnifiedTheme({
  palette: {
    ...palettes.dark,
    primary: {
      main: '#47a847',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#242487',
      paper: '#2f2f92',
    },
    navigation: {
      background: '#2d2d58',
      indicator: '#9BF0E1',
      color: '#b5b5b5',
      selectedColor: '#ffffff',
      navItem: {
        hoverBackground: '#343b58',
      },
      submenu: {
        background: '#343b58',
      },
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#2f2f92', '#343b58'], shape: shapes.wave }),
  },
});

export const mainLightTheme = createUnifiedTheme({
  palette: {
    ...palettes.light,
    background: {
      default: '#e0e1e8',
      paper: '#ffffff',
    },
    navigation: {
      ...palettes.light.navigation,
      background: '#343b58',
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#5f9ea0', '#343b58'], shape: shapes.wave }),
  },
});

export const mainDarkTheme = createUnifiedTheme({
  palette: {
    ...palettes.dark,
    primary: {
      main: '#5f93d3',
      dark: '#4b7ebb',
    },
    background: {
      default: '#2f4f4f',
      paper: '#345d5d',
    },
    navigation: {
      background: '#2d3c30',
      indicator: '#9BF0E1',
      color: '#b5b5b5',
      selectedColor: '#ffffff',
      navItem: {
        hoverBackground: '#404040',
      },
      submenu: {
        background: '#404040',
      },
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#111f16', '#3b3e71'], shape: shapes.wave }),
  },
});
