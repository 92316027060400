import {
  Content,
  // ContentHeader,
  // CreateButton,
  // LinkButton,
  PageWithHeader,
  // SupportButton,
} from '@backstage/core-components';
import {
  CatalogFilterLayout,
  EntityKindPicker,
  EntityListProvider,
  EntityNamespacePicker,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
} from '@backstage/plugin-catalog-react';
import React, { useEffect } from 'react';
import { CatalogTable, DefaultCatalogPageProps } from '@backstage/plugin-catalog';


export const CustomCatalogPage = ({
  columns,
  actions,
  initiallySelectedFilter = 'owned',
}: DefaultCatalogPageProps ) => {
  useEffect(() => {
    const header1 = document.getElementsByTagName('th')[0];
    const span = header1.children[0] as HTMLSpanElement;
    span.click();
  }, []);
  // const createComponentLink = useRouteRef(
  //   catalogPlugin.externalRoutes.createComponent,
  // );
  return (
    <PageWithHeader title="Linebreak Catalog" themeId="home">
      <EntityListProvider>
        <Content>
          {/* <ContentHeader title="">
            <SupportButton>A catalog of all your software.</SupportButton>
          </ContentHeader> */}
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityKindPicker />
              <EntityTypePicker />
              <UserListPicker initialFilter={initiallySelectedFilter} />
              <EntityTagPicker />
              <EntityOwnerPicker />
              <EntityNamespacePicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CatalogTable columns={columns} actions={actions}
                tableOptions={{
                   pageSize: 100,
                  //  pageSizeOptions: [20, 30, 50, 100, 200, 500],
                 }}
              />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </Content>
      </EntityListProvider>
    </PageWithHeader>
  );
};

